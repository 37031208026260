import {Component, inject, Inject, Injectable, OnInit, Pipe} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MatSnackBar} from "@angular/material";
import {JwtHelperService} from "@auth0/angular-jwt";
import {UserService} from "../../_services/user.service";
import {Master, Notion, Subject} from "../pedagogy/pedagogy.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ReportEntity} from "../../datas/report-entity";
import {AlertsComponent} from "../alerts/alerts.component";


@Component({
    selector: 'app-rapport',
    templateUrl: './rapport.component.html',
    styleUrls: ['./rapport.component.scss'],
    providers: [UserService,]
})

export class RapportComponent implements OnInit{

    them;
    user;
    sidePanelOpened = true;
    show = true;
    status = false;
    /*subjects: Subject[] = [
        {id: 1, label: 'Français', code: 'Fr'},
        {id: 2, label: 'Mathématiques', code: 'Math'},
        {id: 3, label: 'Physique-Chimie', code: 'PC'},
        {id: 4, label: 'Anglais', code: 'EN'},
        {id: 5, label: 'Espagnol', code: 'ES'},
        {id: 6, label: 'Histoire géographie', code: 'HG'},
        {id: 7, label: 'Français Langue Etrangère', code: 'FLE'},
        {id: 8, label: 'Philosophie', code: 'PHILO'},
        {id: 9, label: 'SVT', code: 'SVT'},
    ];*/
    subjects: Subject[];
    masters: Master[];
    notions: Notion[];

    apprenants;
    // MESSAGE
    selectedMessage: any;
    showLoader = true;
    messages = [ ];
    Object = Object;
    editReportForm: FormGroup;
    selectedMessageLoader = false;
    selectedSubject = null;

    constructor(private bottomSheet: MatBottomSheet,
                private userService: UserService,
                private formBuilder: FormBuilder,
                public snackBar: MatSnackBar) {
        this.selectedMessage = this.messages[1];
        // this.selectedMessage['brochure'] = 'default.png';
        this.subjects = [
            {
                id: 5,
                label: "Francais",
                code: "Fr"
            },
            {
                id: 6,
                label: "Mathematiques",
                code: "Math"
            },
            {
                id: 7,
                label: "Physique-Chimie",
                code: "PC"
            },
            {
                id: 8,
                label: "Anglais",
                code: "Ang"
            },
            {
                id: 9,
                label: "Espagnol",
                code: "Esp"
            },
            {
                id: 10,
                label: "Histoire geographie",
                code: "HG"
            },
            {
                id: 12,
                label: "Philosophie",
                code: "Philo"
            },
            {
                id: 13,
                label: "SVT",
                code: "SVT"
            },
            {
                id: 15,
                label: "Conditions generales",
                code: "CG"
            },
            {
                id: 16,
                label: "Economie",
                code: "Economie"
            },
            {
                id: 17,
                label: "Enseignement Moral et Physique ",
                code: "EMC"
            },
            {
                id: 18,
                label: "Numerique Siences Informatique",
                code: "NSI"
            },
            {
                id: 19,
                label: "Biologie, Ecologie ",
                code: "BIO ECOLO"
            },
            {
                id: 20,
                label: "HG - Geopolique et Science Politique ",
                code: "HG - Scienc Po "
            },
            {
                id: 21,
                label: "Sciences de l'ingenieur",
                code: "SI"
            },
            {
                id: 22,
                label: "Science Economique Social ",
                code: "SES"
            },
            {
                id: 25,
                label: "Coworking",
                code: "CW"
            },
            {
                id: 26,
                label: "Orientation",
                code: "Or"
            },
            {
                id: 27,
                label: "Assistance Administrative",
                code: "ADMIN"
            },
            {
                id: 28,
                label: "Recherche Logement",
                code: "LOC"
            },
            {
                id: 29,
                label: "Recherche de Stage",
                code: "RS"
            },
            {
                id: 30,
                label: "Coaching Scolaire",
                code: "CS"
            },
            {
                id: 31,
                label: "Arabe",
                code: "Ar"
            },
            {
                id: 32,
                label: "Cours Primaires",
                code: "CP"
            },
            {
                id: 33,
                label: "Logique",
                code: "LGQ"
            },
            {
                id: 34,
                label: "Culture Generale",
                code: "CG"
            },
            {
                id: 36,
                label: "comptabilitÃ©",
                code: "Compta"
            },
            {
                id: 37,
                label: "PrÃ©pa C",
                code: "PrÃ©paration Concours"
            },
            {
                id: 38,
                label: "Raisonnement/Empathie",
                code: "Communication/Ethique"
            },
            {
                id: 42,
                label: "SAT",
                code: "PrÃ©pa"
            },
            {
                id: 43,
                label: "GMAT",
                code: "PrÃ©pa"
            },
            {
                id: 44,
                label: "IELTS",
                code: "PrÃ©pa"
            },
            {
                id: 45,
                label: "LLCE",
                code: "LLCE"
            },
            {
                id: 46,
                label: "Droit",
                code: "Dt"
            },
            {
                id: 47,
                label: "Concours blanc",
                code: "CB"
            },
            {
                id: 48,
                label: "Managment",
                code: "mgmt"
            },
            {
                id: 49,
                label: "Ã‰co-droit",
                code: "Ã‰-D"
            },
            {
                id: 50,
                label: "Neutre",
                code: "NTR"
            },
            {
                id: 51,
                label: "Grand Oral",
                code: "GO"
            }
        ];

        this.masters  = [
            {id: 1, label: 'Pas Acquis'},
            {id: 2, label: 'Moyen'},
            {id: 3, label: 'Assez Bien'},
            {id: 4, label: 'Bien '},
            {id: 5, label: 'Acquis'},
        ];
        this.notions  = [
            {id: 1, label: 'Hebdomadaire'},
            {id: 2, label: 'Mensuel'},
        ];

    }

    ngOnInit() {

        this.editReportForm = this.formBuilder.group({

            date_report: ['', Validators.required],
            description: ['', Validators.required],
            subject: ['', [Validators.required]],
            title: ['', [Validators.required]],
            to_work_on: ['', [Validators.required]],
            htp: ['', [Validators.required]],
            hma: ['', [Validators.required]],
            master: ['', [Validators.required]],
            notion: ['', []],
        });
        this.user = localStorage.getItem('username');
        this.getSubjects();
        this.getStudents();


    }
    onSelect(apprenant: Object[]): void {
        this.selectedMessage = apprenant;
        this.show = false;
        this.getReports(apprenant);
    }
    getStudents(){
        this.userService.getApprenantsByIntervenantReports().subscribe( res => {
            this.apprenants = JSON.parse(res);

            this.showLoader = false;

        });
    };

    getSubjects(){
       // this.subjects = this.subjects;
        /*this.userService.getIntervenantSubjects().subscribe( res => {
            this.subjects = res;
        });*/
    }

    getReports(apprenant){
        console.log(this.subjects);
        console.log(this.masters);
        let subs = this.subjects;
        let mstrs = this.masters;
        let nton = this.notions;
        this.userService.getReportsByIntervenant(apprenant.id).subscribe(

            data => {

                if( data == 0 ){
                    //this.selectedMessage = this.messages[0]
                }

                data.forEach(function(element) {
                    //console.log(element.date_report);
                    let madate = new Date(element.date_report * 1000);
                    //new Date(element.date_report * 1000).setDate(date.getDate() + 1);
                    madate.setDate(madate.getDate() + 2);
                    element.date_report = madate;
                    let xx = subs.find((el) => el.id == element.subject);
                    element.subject = xx;
                    let yy = mstrs.find((el) => el.id == element.master);
                    element.master = yy;
                    let zz = nton.find((el) => el.id == element.notion);
                    element.notion = zz;
                  /*  let xx = subs.find((el) => el.id == element.subject);
                    element.subject = xx;
                    let yy = mstrs.find((el) => el.id == element.master);
                    element.master = yy;*/


                });
                this.messages = null;

                this.messages = data;

                console.log(data);
                this.selectedMessageLoader = true;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

    getSubject(sub){
        return sub;
    }

    onEdit(current){
        console.log(this.editReportForm);
        let report = new ReportEntity();
        report.id = current.id;
        report.date_report = this.editReportForm.value.date_report;
        report.description = this.editReportForm.value.description;
        report.title = this.editReportForm.value.title;
        report.subject = this.editReportForm.value.subject;
        report.intervenant = this.user;

        report.master = this.editReportForm.value.master;
        report.to_work_on = this.editReportForm.value.to_work_on;
        report.htp = this.editReportForm.value.htp;
        report.hma = this.editReportForm.value.hma;
        report.notion = this.editReportForm.value.notion;
        console.log(report);
        this.userService.editReport(report).subscribe(

            data => {

                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-seccus'],
                    data:data
                });
                this.getReports(this.selectedMessage)
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });

    }



    addNewReport(): void {
        this.bottomSheet.open(NewReportModal, {
            data: { intervenant: this.user , apprenants : this.apprenants},
        });
        if(this.selectedMessage){
            this.getReports(this.selectedMessage);
        }

    }

    isOver(): boolean {
        return window.matchMedia(`(max-width: 960px)`).matches;
    }
}

import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {count} from "rxjs-compat/operator/count";
//import {log} from "util";

@Component({
    selector: 'new-report-modal',
    templateUrl: 'new-report-modal.html',
})
export class NewReportModal implements OnInit{


    constructor(private bottomSheetRef: MatBottomSheetRef<NewReportModal>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    ) {}

    openLink(event: MouseEvent): void {
        this.bottomSheetRef.dismiss();
        event.preventDefault();
    }



    ngOnInit(){



    }



}

